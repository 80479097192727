import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendToken } from "../../services/captchaService";

class Captcha extends Component {
  onChange = async value => {
    const { data } = await sendToken(value);
    this.props.recaptchaValidate({ validateStatus: data.status });
  };

  render() {
    return (
      <div>
        <br />
        <ReCAPTCHA
          sitekey="6LfR7LgUAAAAABiAE0Ct9KKjWxKPLWKDwB55yJ9b"
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default Captcha;
