import React from "react";

function ShowSpinNote() {
  return (
    <button
      style={{ width: "100%" }}
      className="btn btn-primary"
      type="button"
      disabled
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <p>
        I use Herokuapp, so if no one visits me for an hour, the server goes to
        sleep ... Please wait for it to wake up :)
      </p>
    </button>
  );
}

export default ShowSpinNote;
