import React, { Component } from "react";
import { getCustomers } from "../services/customerService";
import Pagination from "./common/pagination";
import { paginate } from "../utils/paginate";
import ListInline from "./common/listInline";
import CustomersTable from "./customersTable";
import _ from "lodash";
import SearchBox from "./common/searchBox";

class Customers extends Component {
  state = {
    customers: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    width: window.innerWidth
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    //console.log(window.innerWidth);
  };

  componentWillMount() {
    this.updateDimensions();
  }

  async componentDidMount() {
    const { data: customers } = await getCustomers();
    this.setState({ customers });

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      customers: allMovies
    } = this.state;

    let filtered = allMovies;
    if (searchQuery)
      filtered = allMovies.filter(m =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: movies };
  };

  render() {
    //const { length: count } = this.state.movies; //rename length as count
    //const count = this.state.movies.length;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: movies } = this.getPagedData();

    return (
      <div>
        <p>Showing {totalCount} skills in the database </p>
        <SearchBox value={searchQuery} onChange={this.handleSearch} />
        <CustomersTable
          movies={movies}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onLike={this.handleLike}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

ListInline.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default Customers;
