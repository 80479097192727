import React, { Component } from "react";
import hknImage from "../media/images/hkn.jpg";
import { Image, ListGroup } from "react-bootstrap";
import AboutMe from "./pages/aboutMe";
import Projects from './pages/projects';
import ThisApp from './pages/thisApp';
import Moocs from './pages/moocs';
import MySkills from './pages/mySkills';
import "../css/home.css";
//import "../css/home.min.css";

class Home extends Component {
  state = {
    content: 'about',
    width: window.innerWidth,
  };

  selectContent() {
    switch (this.state.content) {
      case 'about':
        return <AboutMe />;
      case 'thisApp':
        return <ThisApp />;
      case 'Mooc':
        return <Moocs />;
      case 'MySkills':
        return <MySkills />;
      case 'Projects':
        return <Projects />;
      default:
        return <AboutMe />;
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    //console.log(window.innerWidth);
  };

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <div className="row">
        <div className={this.state.width >= 768 ? 'col-2' : ''}>
          <Image
            className="img-fluid"
            src={hknImage}
            alt="profile"
            roundedCircle
          />
          <p
            className="myP"
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontFamily: 'Saira',
              paddingTop: 10,
            }}
          >
            FULL STACK DEVELOPER
          </p>
          <ListGroup
            variant="flush"
            style={{ paddingTop: 20, cursor: 'pointer', fontFamily: 'Muli' }}
          >
            <ListGroup.Item
              onClick={() => this.setState({ content: 'MySkills' })}
            >
              My Skills
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => this.setState({ content: 'Projects' })}
            >
              Projects
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => this.setState({ content: 'thisApp' })}
            >
              This App
            </ListGroup.Item>
            <ListGroup.Item onClick={() => this.setState({ content: 'about' })}>
              About Me
            </ListGroup.Item>
            <ListGroup.Item onClick={() => this.setState({ content: 'Mooc' })}>
              edX, Inc. (MOOC platform)
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className={this.state.width >= 768 ? 'col-10' : ''}>
          {this.selectContent()}
        </div>
      </div>
    );
  }
}

export default Home;

//roundedCircle  rounded  thumbnail
