import React, { Component } from "react";
import { getGenres } from "../../services/static/staticGenreService";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import ListInline from "../common/listInline";
import CollapseTable from "../collapseTable";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import LoginSpinner from "../common/loginSpinner";
import LoginSpinnerNote from "../common/loadingSpinnerNote";
import { getAdminSkills } from "../../services/static/adminSkills.js";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

class Skills extends Component {
  state = {
    movies: [],
    genres: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    width: window.innerWidth,
    loading: true,
    keySkillOption: 'All',
    skillType: ['All', 'Key']
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillMount() {
    this.updateDimensions();
  }

  async componentDidMount() {
    const  data =  getGenres();

    const genres = [{ _id: "", name: "All Genres" }, ...data];

    const movies = getAdminSkills();

    this.setState({ movies, genres, loading: false });

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  mapToViewModel(movie) {

    return {
      _id: movie._id,
      title: movie.title,
      genreId: movie.genre._id,
      numberInStock: movie.numberInStock,
      dailyRentalRate: movie.dailyRentalRate,
      liked: movie.liked
    };
  }

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  handleGenreSelect = genre => {
    //console.log(genre);
    this.setState({ selectedGenre: genre, currentPage: 1, searchQuery: "" });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleGenreSelect = genre => {
    this.setState({ selectedGenre: genre, searchQuery: "", currentPage: 1 });
  };

  // handleKeySkillOption = option => {
  //   this.setState({ keySkillOption: option });
  // }

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      selectedGenre,
      sortColumn,
      searchQuery,
      keySkillOption,
      skillType,
      movies: allMovies,
    } = this.state;

    let filtered = allMovies;
    if (searchQuery)
      filtered = allMovies.filter(m =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allMovies.filter(m => m.technology._id === selectedGenre._id);
    if (keySkillOption === skillType[1])
      filtered = filtered.filter((item) => item.keySkill);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    let movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: movies };
  };

  render() {
    //const { length: count } = this.state.movies; //rename length as count
    //const count = this.state.movies.length;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: movies } = this.getPagedData();

    return (
      <div className="container">
        <div>{this.state.loading ? <LoginSpinner /> : ''}</div>
        <div className="row d-flex justify-content-center">
          <ListInline
            style={{ marginBottom: 20 }}
            genres={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="row">
          <div className="d-flex align-items-center">
            <BootstrapSwitchButton
              // eslint-disable-next-line react/style-prop-object
              style="w-100 h-25"
              onlabel="Key"
              onstyle="danger"
              offlabel="All"
              offstyle="success"
              onChange={(checked) => {
                const skilltype = checked
                  ? this.state.skillType[1]
                  : this.state.skillType[2];
                this.setState({ keySkillOption: skilltype });
              }}
            />
          </div>
          <div className="d-flex align-items-center ml-5 pt-4">
            <p>
              Showing {totalCount} skills in{' '}
              <label
                className={
                  this.state.keySkillOption === this.state.skillType[1]
                    ? 'text-danger font-weight-bold'
                    : 'text-success font-weight-bold'
                }
              >
                {this.state.keySkillOption} Skills
              </label>
            </p>
          </div>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <CollapseTable
            movies={movies}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            titles={['Name', 'Technology', 'SkillLevel']}
          />
          <div>
            {this.state.loading ? (
              <div style={{ textAlign: 'center' }}>
                {' '}
                <LoginSpinnerNote />
              </div>
            ) : (
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ListInline.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default Skills;
