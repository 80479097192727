import React from "react";
import Background_all from "../../media/images/allTech.png";
import Background_backend from "../../media/images/backend.png";
import Background_frontend from "../../media/images/frontend.png";
import Background_tech from "../../media/images/tech.png";
import Background_ux_ui from "../../media/images/ux_ui.png";

const ListInline = props => {
  const { genres, textProperty, valueProperty, selectedItem } = props;
  const className = "list-inline-item list-inline-item-action";
  const src = {
    "All Genres": Background_all,
    "Back-End": Background_backend,
    "Front-End": Background_frontend,
    "UI & UX DESIGN": Background_ux_ui,
    Teachnologies: Background_tech
  };

  return (
    <ul className="list-inline">
      {/* {console.log("tests:" + window.innerWidth > 768)} */}
      {genres.map(item => (
        <li
          className={item === selectedItem ? className + " active" : className}
          style={{
            cursor: "pointer"
          }}
          key={item[valueProperty]}
          onClick={() => props.onItemSelect(item)}
        >
          <img
            src={src[item[textProperty]]}
            alt={item[textProperty]}
            style={{ width: "100px" }}
          />
        </li>
      ))}
    </ul>
  );
};

export default ListInline;
