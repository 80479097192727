import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";
class CustomersTable extends Component {
  columns = [
    {
      path: "title",
      label: "Companies",
      content: customer => (
        <Link to={`/customer/${customer._id}`}>{customer.name}</Link>
      )
    }
  ];

  constructor() {
    super();
  }

  render() {
    const { movies, sortColumn, onSort } = this.props;
    return (
      <Table
        columns={this.columns}
        data={movies}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CustomersTable;
