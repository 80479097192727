let adminSkills = [
  {
    title: "VUE",
    experienceTime: "10",
    skillLevel: "Expert",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
        name: "Front-End"
    },
    _id: "5c8001803a136299108ae35db",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "NUXT.js",
        "SSR universal & spa",
        "Build server-side-rendered single-page-applications (SPAs)",
        "Vue.js Applications - all the Way from Small and Simple Ones up to Large Enterprise-level Ones",
        "Leverage Vue.js in both Multi- and Single-Page-Applications (MPAs and SPAs)",
        "Understand the Theory behind Vue.js and use it in Real Projects"
      ]
    }
  },
  {
    title: "Docker and Kubernetes",
    experienceTime: "10",
    skillLevel: "Advanced",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef03b",
      name: "Technologies"
    },
    _id: "5c8002803a136299108ae35db",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Docker from scratch, no previous experience required",
       "Solve concurrency issues in a distributed systems environment",
       "Build a Server-Side Rendered React App to render data from your microservices",
       "Share reusable code between multiple Express servers using custom NPM packages",
       "Communicate data between services using a lightning-fast event bus",
       "Deploy a multi-service app to the cloud with Docker and Kubernetes",
       "Leverage your Javascript skills to build a complex web app",
       "Understand how enterprise companies design their infrastructure",
       "Write comprehensive tests to ensure each service works as designed"
      ]
    }
  },
  {
    title: "Microservices with Node JS and NodeJS",
    experienceTime: "10",
    skillLevel: "Advanced",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "5c8001803a136299108ae35db",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Architect large, scalable apps using a collection of microservices",
        "Build a CI + CD pipeline from scratch with Github, Travis CI, and AWS",
        "Automatically deploy your code when it is pushed to Github!",
        "Master the Docker CLI to inspect and debug running containers",
        "Understand the purpose and theory of Kubernetes by building a complex app"
      ]
    }
  },
  {
    title: "NodeJS",
    experienceTime: "10",
    skillLevel: "Expert",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "5c799803a136299108ae35db",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Express (MERN)",
        "Authentication and Authorization, re-capthca",
        "Database Scheme Creation",
        "Data Protection",
        "Error-Handling Skills",
        "Node module system",
        "Node Package Manager (NPM)",
        "Asynchronous JavaScript",
        "Storing complex data",
        "Data Validation",
        "Authentication and authorization",
        "Handling and logging errors the right way",
        "Unit and integration testing",
        "Test-driven development (TDD)",
        "RESTful APIs and API Communications / Web Services – SOAP / REST, Express/ REST based Micro services to provide all the CRUD capabiliEes."
      ]
    }
  },
  {
    title: "TypeScript",
    experienceTime: "10",
    skillLevel: "Expert",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "5c800803a136299108ae35db",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Integrate Typescript into NodeJS, React/Redux or Express projects",
        "Using with NodeJS microservices",
        "Master design patterns for building large applications",
        "Understand Composition vs Inheritance, and when to use each",
        "Assemble reusable boilerplates for your own Typescript projects",
        "Data Protection",
        "Error-Handling Skills",
        "Node module system",
        "Node Package Manager (NPM)",
        "Asynchronous JavaScript",
        "Write reusable code powered by classes and interfaces",
        "Storing complex data",
        "Data Validation",
        "Authentication and authorization",
        "Handling and logging errors the right way",
        "Unit and integration testing",
        "Test-driven development (TDD)",
        "RESTful APIs and API Communications / Web Services – SOAP / REST, Express/ REST based Micro services to provide all the CRUD capabiliEes."
      ]
    }
  },
  {
    title: "Web services REST, Web API, SOAP",
    experienceTime: "10",
    skillLevel: "Expert",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "5c7133d5a136299108ae35d9",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Database Scheme Creation",
        "Authentication and Authorization, re-capthca",
        "Data Protection",
        "Error-Handling Skills",
        "Storing complex data",
        "Data Validation",
        "Authentication and authorization",
        "Handling and logging errors the right way",
        "Unit and integration testing",
        "Test-driven development (TDD)",
        "RESTful APIs and API Communications / Web Services – SOAP / REST, Express/ REST based Micro services to provide all the CRUD capabiliEes."
      ]
    }
  },
  {
    title: "Javascript",
    experienceTime: "10",
    skillLevel: "Expert",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
      name: "Front-End"
    },
    _id: "5c7837c9a136299108ae35d8",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "The core principles of OOP: encapsulation, abstraction, inheritance and polymorphism",
        "OOP concepts",
        "All about objects, their properties, property descriptors, getters and setters",
        "factory and constructor functions",
        "Prototypes and prototypical inheritance",
        "ES6+ features used in object-oriented programming"
      ]
    }
  },
  {
    title: "ReactJS",
    experienceTime: "10",
    skillLevel: "Advanced",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
      name: "Front-End"
    },
    _id: "5c7847c9t6a136299108ae35d8",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Modern JavaScript",
        "Components",
        "Tables and lists",
        "Pagination, sorting and searching",
        "Forms with validation",
        "Routing and navigation",
        "Calling HTTP services",
        "Authentication and authorization",
        "Handling and logging errors"
      ]
    }
  },
  {
    title: "AWS, AWSCloud architecture, Web Services",
    experienceTime: "10",
    skillLevel: "Certificated",
    liked: false,
    technology: {
      _id: "5c7xxxbcdcd6594be38ef03b",
      name: "Technologies"
    },
    _id: "2c7847d5a136299108qqq5d9",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: ["AWS Certified Cloud Practitioner validation Number T7Y84LR2MNBQQ8S1", "Understanding of AWS. Cloud architecture, hands-on experience of API Gateway, AWS Lambda, AWS S3, Amazon DynamoDB, AWS,EKS..."]
    }
  },
  {
    title: "Java",
    experienceTime: "6",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "5cv847efa136299108ae35da",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: [
        "OOPs Concepts & Patterns, Multithreading and Synchronization, Developing, debugging, data structures"
      ]
    }
  },
  {
    title: "jQuery",
    experienceTime: "7",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
      name: "Front-End"
    },
    _id: "5c7817c9a136299108ae35d8",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Designing and implementing the User Interface using React JS, HTML5, CSS, and Bootstrapfor responsive design for the exposed enterprise application. "
      ]
    }
  },
  {
    title: "HTML5 styled with CSS3 and Bootstrap",
    experienceTime: "7",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
      name: "Front-End"
    },
    _id: "5c7347c9a136299108ae35d8",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Designing and implementing the User Interface using React JS, HTML5, CSS, and Bootstrapfor responsive design for the exposed enterprise application. "
      ]
    }
  },
  {
    title: "TTD & Testing: NUnit, Jest & enzyme",
    experienceTime: "7",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef03b",
      name: "Technologies"
    },
    _id: "5c7547d5a136259108ae35d9",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: ["Unit testing", "Integration testing"]
    }
  },
  {
    title: "CI/CD (DevOps, Git, Jenkins, AWS, EKS, Kops, TravisCI)",
    experienceTime: "10",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef03b",
      name: "Technologies"
    },
    _id: "2c7847d5a136299108ae35d9",
    keySkill: true,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Install, Configure And Operationalize Jenkins",
        "Build Projects Automatically",
        "Configuring Jenkins to build CI/CD pipeline which includes to trigger auto builds from git, auto promote builds from one environment to another, auto version."
      ]
    }
  },
  {
    title: "Sketch",
    experienceTime: "10",
    skillLevel: "Independent",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef038",
      name: "UI & UX DESIGN"
    },
    _id: "4c7847d5a136299108ae35d9",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: ["Basic skills"]
    }
  },
  {
    title: "Balsamiq",
    experienceTime: "10",
    skillLevel: "Independent",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef038",
      name: "UI & UX DESIGN"
    },
    _id: "7c7847d5a136299108ae35d9",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: ["Basic skills"]
    }
  },
  {
    _id: "5c71f6bcdcr6594be38ef02b",
    title: "C#",
    skillLevel: "Advanced",
    experienceTime: 2,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: [
        "Developed and tested integration systems",
        "Migrating existing application into REST based Micro services to provide all the CRUD capabilities",
        "Asynchronous Programming with Async / Await"
      ]
    }
  },
  {
    title: "Angular 9",
    experienceTime: "10",
    skillLevel: "Intermediate",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef031",
      name: "Front-End"
    },
    _id: "5c8001804a136299108ae35db",
    keySkill: false,
    info: {
      title: "What can I do with this skill?",
      content: [
        "single page applications with Angular and Typescript"
      ]
    }
  },
  {
    title: "Python",
    experienceTime: "10",
    skillLevel: "Beginner",
    liked: false,
    technology: {
      _id: "5c73f6bcdcd6594be38ef02a",
      name: "Back-End"
    },
    _id: "9x7167c7x134299108ae35d8",
    keySkill: false,
    info: {
      title: "Python is on the way!",
      content: ["Soon..."]
    }
  },
];
export function getAdminSkills() {
  return adminSkills;
}
