import React from "react";

const AboutMe = () => {
  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="about"
    >
      <div className="w-100">
        <h1 className="mb-5">
          HAKAN ERDEM
          <span className="mytext-primary"> TEMIZ</span>
        </h1>
        <div className="subhead">
          <h4 className="my_a">ABOUT ME</h4>
        </div>
        <p className="myP" style={{ paddingBottom: '20px' }}>
          &nbsp; &nbsp; Hi, I am a Full-stack engineer with over 4 years of work
          experience in international companies within the development industry.
          Specializing in software architecture and web development with an
          in-depth knowledge of Javascript, Typescript, NodeJS, MongoDB,
          ReactJS, Vue; Microservices Architecture (With Node.Js and
          TypeScript), AWS, Cloud architecture, Docker, kubernetes, DevOps and
          numerous other languages and technologies specialized in Back-end
          development.
        </p>
        <p className="myP" style={{ paddingTop: 10 }}>
          &nbsp; &nbsp; My aim here is to introduce myself more, share what I
          know practically, and meet new people. Please feel free to contact me.
        </p>
        <div className="social-icons" style={{ paddingTop: 40 }}>
          <a href="https://www.linkedin.com/in/hakan-erdem-temiz/">
            <i className="fab fa-linkedin" />
          </a>
          <a href="https://github.com/hakan-erdem-temiz">
            <i className="fab fa-github" />
          </a>
          <a href="https://twitter.com/HakanErdemTemiz">
            <i className="fab fa-twitter" />
          </a>
          <a href="https://www.instagram.com/hakanerdemtemiz/">
            <i className="fab fa-instagram" />
          </a>
          <a className="my_a" href="mailto:hakanerdemtemiz@gmail.com">
            <i className="fa fa-envelope" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
