import React from "react";
import Background_all from "../../media/images/allTech.png";
import Background_backend from "../../media/images/backend.png";
import Background_frontend from "../../media/images/frontend.png";
import Background_tech from "../../media/images/tech.png";
import Background_ux_ui from "../../media/images/ux_ui.png";

const ListGroup = props => {
  const { genres, textProperty, valueProperty, selectedItem } = props;
  const className =
    window.innerWidth > 768
      ? "list-group-item-flush list-group-item-action"
      : "list-group-item list-group-item-action";

  const src = {
    "All Genres": Background_all,
    "Back-End": Background_backend,
    "Front-End": Background_frontend,
    "UI & UX DESIGN": Background_ux_ui,
    Teachnologies: Background_tech
  };

  const colors = {
    "All Genres": " list-group-item-success",
    "Back-End": " list-group-item-warning",
    "Front-End": " list-group-item-danger",
    "UI & UX DESIGN": " list-group-item-info",
    Teachnologies: " list-group-item-primary"
  };

  return (
    <ul className="list-group">
      {console.log('tests:' + window.innerWidth > 768)}
      {genres.map((item) => (
        <li
          className={
            item === selectedItem
              ? className + ' active' + colors[item[textProperty]]
              : className + colors[item[textProperty]]
          }
          style={{ cursor: 'pointer' }}
          key={item[valueProperty]}
          onClick={() => props.onItemSelect(item)}
        >
          {window.innerWidth > 768 ? (
            <img
              src={src[item[textProperty]]}
              alt={item[textProperty]}
              style={{ width: '130px' }}
            />
          ) : (
            item[textProperty]
          )}
        </li>
      ))}
    </ul>
  );
};

export default ListGroup;
