let movies = [
  {
    _id: "5b21ca3eeb7f6fbccd471815",
    liked: false,
    title: "C# Advanced",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Back-end" },
    numberInStock: 6,
    skillLevel: "Expert",
    publishDate: "2018-01-03T19:04:28.809Z",
    info: {
      title: "What I have learned?",
      content: [
        "Exception Handling",
        "Generics",
        "Delegates",
        "Events",
        "Lambda Expressions",
        "Extensions Methods",
        "LINQ",
        "Nullable Types",
        "Dynamics",
        "Asynchronous Programming with Async / Await"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471816",
    liked: false,
    title: "OOP in JavaScript",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Back-end" },
    numberInStock: 5,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "The core principles of OOP: encapsulation, abstraction, inheritance and polymorphism",
        "How to implement OOP concepts in JavaScript",
        "All about objects, their properties, property descriptors, getters and setters",
        "Difference between factory and constructor functions",
        'How "this" works in JavaScript',
        "Prototypes and prototypical inheritance: one of the confusing parts of JavaScript",
        "ES6+ features used in object-oriented programming"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471816",
    liked: false,
    title: "Javascript Essentials",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Back-end" },
    numberInStock: 5,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "syntax that can apply to any language",
        "what is a programming language and the basic concepts for beginners.",
        "Understand what is Javascript in it's truest form.",
        "Know the basic syntax of Javascript.",
        "Know some hidden quirks in Javascript.",
        "Understand DOM Manipulation"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471817",
    liked: false,
    title: "EnEty Framework in Depth",
    technology: { _id: "5b21ca3eeb7f6fbccd471820", name: "Back-end" },
    numberInStock: 8,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Understand the differences between database-first and code-first workflows",
        "Build a domain model using database-first workflow",
        "Build a domain model using code-first workflow (with an existing or a new database)",
        "Use code-first migrations to upgrade or downgrade your database",
        "Override code-first conventions (using data annotations and fluent API)",
        "Query data using LINQ (using query syntax and extension methods)",
        "Use eager loading, lazy loading and explicit loading effectively",
        "Add, update and remove objects",
        "Use additional tools to profile and troubleshoot your applications",
        "Understand what the repository pattern is, what and if you need it, and how to implement it",
        "Apply best practices with Entity Framework"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471819",
    liked: false,
    title: "Jenkins DevOps",
    technology: { _id: "5b21ca3eeb7f6fbccd471814", name: "Tool" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Understand What Jenkins Is All About",
        "How To Install, Configure And Operationalize Jenkins",
        "How To Build Projects Automatically Using Maven",
        "How To Build Projects Automatically Using Gradle",
        "How To Manage Your Build Artifacts Using Artifactory",
        "How To Manage Your Relational Database Schema Using Sqitch"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181a",
    liked: false,
    title: "unitTesting for C#",
    technology: { _id: "5b21ca3eeb7f6fbccd471814", name: "Tool" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "senior coder secrets - best practices to write great unit tests",
        "tips and tricks to keep your tests clean, trustworthy and maintainable",
        "the pitfalls to avoid – anti-patterns",
        "how to refactor legacy, untestable code into loosely-coupled and testable code",
        "all about dependency injection – the one thing every coder needs to know",
        "the power of mocks – when and how to use them and when to avoid"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181b",
    liked: false,
    title: "Mastering React",
    technology: { _id: "5b21ca3eeb7f6fbccd471814", name: "Front-end" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Modern JavaScript",
        "Components",
        "Tables and lists",
        "Pagination, sorting and searching",
        "Forms with validation",
        "Routing and navigation",
        "Calling HTTP services",
        "Authentication and authorization",
        "Handling and logging errors"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181e",
    liked: false,
    title: "NodeJS Guide",
    technology: { _id: "5b21ca3eeb7f6fbccd471820", name: "Back-end" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Node module system",
        "Node Package Manager (NPM)",
        "Asynchronous JavaScript",
        "Useful ES6+ features",
        "Implementing CRUD operations",
        "Storing complex data in MongoDB",
        "Data Validation",
        "Authentication and authorization",
        "Handling and logging errors the right way",
        "Unit and integration testing",
        "Test-driven development (TDD)"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181f",
    liked: false,
    title: "The Complete 201 Web Development Bootcamp",
    technology: { _id: "5b21ca3eeb7f6fbccd471820", name: "BootCamp" },
    numberInStock: 4,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Front-End Web Development",
        "HTML 5",
        "CSS 3",
        "Bootstrap 4",
        "Javascript ES6",
        "DOM Manipulation",
        "jQuery",
        "Bash Command Line",
        "Git, GitHub and Version Control",
        "Backend Web Development",
        "Node.js",
        "NPM",
        "Express.js",
        "EJS",
        "REST",
        "APIs",
        "Databases",
        "SQL",
        "MongoDB",
        "Mongoose",
        "Web Design",
        "Deployment with GitHub Pages, Heroku and MongoDB"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471821",
    liked: false,
    title: "Jenkins",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "DevOps" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Understand What Jenkins Is All About",
        "How To Install, Configure And Operationalize Jenkins",
        "How To Build Projects Automatically Using Maven",
        "How To Build Projects Automatically Using Gradle",
        "How To Manage Your Build Artifacts Using Artifactory",
        "How To Manage Your Relational Database Schema Using Sqitch"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fbccd471831",
    liked: false,
    title: "Advanced React, Redux & Testing",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Front-end" },
    numberInStock: 7,
    skillLevel: "Expert",
    info: {
      title: "What I have learned?",
      content: [
        "Advanced Redux",
        "HOC",
        "Jest & Enzyme",
        "Server and Client Side Advance"
      ]
    }
  },
  {
    _id: "5b21ca3eeb7f6fuccd471821",
    liked: false,
    title: "The Complete ASP.NET MVC 5 COurse",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Back-end" },
    numberInStock: 7,
    skillLevel: "Intermediate",
    info: {
      title: "What I have learned?",
      content: [
        "Implement CRUD operations",
        "Build forms with validation",
        "Build RESTful services using ASP.NET Web API",
        "Use Entity Framework code-first workflow to build a model ",
        "Implement security, authentication and authorization using ASP.NET Identity",
        "Use powerful jQuery plugins",
        "Optimize application's performance  ",
        "Secure application settings and connection strings"
      ]
    }
  },
  {
    _id: "5b21ca3eeo7f6fbccd471821",
    liked: false,
    title: "The Complete Python Programming Course for Beginners",
    technology: { _id: "5b21ca3eeb7f6fbccd471818", name: "Back-end" },
    numberInStock: 7,
    skillLevel: "Beginner",
    info: {
      title: "Python is on the way!",
      content: ["Soon..."]
    }
  }
];

export function getMovies() {
  return movies;
}
