import React from 'react';

const MySkills = () => {
  return (
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="about"
    >
      <div className="w-100">
        <h1 className="mb-5">
          {' '}
          <i className="fas fa-bullhorn" />
          <i className="fas fa-wind" /> &nbsp; &nbsp; Coming
          <span className="mytext-primary"> Soon!</span>
        </h1>
        <div className="subhead">
          <p className="my_a">
            I'm editing my projects, I'll start publishing soon. Until then, you
            can follow my projects on GitHub.
          </p>
        </div>
        <p className="myP" style={{ paddingTop: 10 }}>
          &nbsp; &nbsp; My aim here is to introduce myself more, share what I
          know practically, and meet new people. Please feel free to contact me.
        </p>
        <div className="social-icons" style={{ paddingTop: 40 }}>
          <a href="https://github.com/hakan-erdem-temiz">
            <i className="fab fa-github" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MySkills;
