import React from "react";
import { Component } from "react";

class Rentals extends Component {
  render() {
    return <h1>Rentals</h1>;
  }
}

export default Rentals;
