import React, { Component } from "react";
import { getCustomers } from "../services/customerService";
import { getGenres } from '../services/genreService';
import Pagination from "./common/pagination";
import { paginate } from '../utils/paginate';
import ListInline from "./common/listInline";
import CollapseTable from "./collapseTable";
import _ from "lodash";
import SearchBox from "./common/searchBox";
import LoginSpinner from "./common/loginSpinner";
import LoginSpinnerNote from "./common/loadingSpinnerNote";
import { getAdminSkills } from '../services/static/adminSkills.js';

class Skills extends Component {
  state = {
    movies: [],
    genres: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    width: window.innerWidth,
    loading: true
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    //console.log(window.innerWidth);
  };

  componentWillMount() {
    this.updateDimensions();
  }

  async componentDidMount() {
    const { data } = await getGenres();
    const genres = [{ _id: "", name: "All Genres" }, ...data];

    //const { data: allMovies } = await getMovies();
    // const movies = allMovies.filter(skill => {
    //   let adminSkill = admin.skills.some(s => s._id === skill._id);
    //   if (adminSkill) return _.pick(adminSkill, ["_id"]);
    //   return adminSkill;
    // });

    let movies;
    const userId = this.props.match.params.id;
    if (userId) {
      const { data: customers } = await getCustomers();
      const user = customers.find(c => c._id == userId);
      movies = user.skills;
    } else {
      movies = getAdminSkills();
    }

    this.setState({ movies, genres, loading: false });

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  mapToViewModel(movie) {
    //console.log("movie:" + movie);
    return {
      _id: movie._id,
      title: movie.title,
      genreId: movie.genre._id,
      numberInStock: movie.numberInStock,
      dailyRentalRate: movie.dailyRentalRate,
      liked: movie.liked
    };
  }

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  handleGenreSelect = genre => {
    //console.log(genre);
    this.setState({ selectedGenre: genre, currentPage: 1, searchQuery: "" });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleGenreSelect = genre => {
    this.setState({ selectedGenre: genre, searchQuery: "", currentPage: 1 });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      selectedGenre,
      sortColumn,
      searchQuery,
      movies: allMovies
    } = this.state;

    let filtered = allMovies;
    if (searchQuery)
      filtered = allMovies.filter(m =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allMovies.filter(m => m.technology._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: movies };
  };

  render() {
    //const { length: count } = this.state.movies; //rename length as count
    //const count = this.state.movies.length;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: movies } = this.getPagedData();

    return (
      <div>
        <div>{this.state.loading ? <LoginSpinner /> : ""}</div>
        <div className="row">
          <ListInline
            style={{ marginBottom: 20 }}
            genres={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="row">
          <p>Showing {totalCount} skills in the database </p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <CollapseTable
            movies={movies}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            titles={["Name", "Technology", "SkillLevel"]}
          />
          <div>
            {this.state.loading ? (
              <div style={{ textAlign: "center" }}>
                {" "}
                <LoginSpinnerNote />
              </div>
            ) : (
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ListInline.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default Skills;
