import React from "react";

// class Customers extends Component {
//   render() {
//     return "Customers";
//   }
// }

const NotFoundComponent = () => {
  return <h1>NotFound!</h1>;
};

export default NotFoundComponent;
