import React, { Component } from "react";
import Table from "./common/table";
import { Collapse } from "react-bootstrap";
class CollapseTable extends Component {
  state = {
    titles: []
  };

  constructor() {
    super();
  }

  render() {
    const { movies, sortColumn, onSort, titles } = this.props;
    const columns = [
      {
        path: "title",
        label: titles[0],
        content: movie => (
          <div>
            <button
              onClick={() => {
                this.state.open === movie._id
                  ? this.setState({ open: "" })
                  : this.setState({ open: movie._id });
              }}
              className="btn btn-link text-left"
              aria-controls="example-collapse-text"
              aria-expanded={this.state.open}
            >
              {movie.title}
            </button>
            <Collapse in={this.state.open === movie._id}>
              <div id="example-collapse-text">
                <strong> {movie.info ? movie.info.title : ""}</strong>
                <i>
                  {movie.info
                    ? movie.info.content.map(element => (
                        <p style={{ marginBottom: 2 }}>
                          <i
                            className="fas fa-check-circle"
                            style={{
                              marginInlineEnd: 10,
                              marginRight: 10,
                              color: "#868e96"
                            }}
                          />
                          {element}
                        </p>
                      ))
                    : ""}
                </i>
              </div>
            </Collapse>
          </div>
        )
      },
      { path: "technology.name", label: titles[1] },
      { path: "skillLevel", label: titles[2] }
    ];

    return (
      <Table
        columns={columns}
        data={movies}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CollapseTable;
