import http from "./httpService";

export function getToken() {
  return http.get("/technologies");
}

export function sendToken(token) {
  return http.post("/captcha", {
    "g-recaptcha-response": token
  });
}
