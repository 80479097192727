import React, { Component } from "react";
import CollapseTable from "../collapseTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { getMovies } from "../../services/static/fakeMoocsService.js";
import _ from "lodash";

class Moocs extends Component {
  state = {
    sortColumn: { path: "title", order: "asc" },
    currentPage: 1,
    pageSize: 4,
    movies: []
  };

  componentDidMount() {
    const movies = getMovies();
    this.setState({ movies });
  }

  handlePageChange = page => {
    //console.log(page);
    this.setState({ currentPage: page });
  };

  handleGenreSelect = genre => {
    //console.log(genre);
    this.setState({ selectedGenre: genre, currentPage: 1, searchQuery: "" });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleGenreSelect = genre => {
    this.setState({ selectedGenre: genre, searchQuery: "", currentPage: 1 });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      selectedGenre,
      sortColumn,
      searchQuery,
      movies: allMovies
    } = this.state;

    let filtered = allMovies;
    if (searchQuery)
      filtered = allMovies.filter(m =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allMovies.filter(m => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: movies };
  };

  render() {
    const { sortColumn, currentPage, pageSize } = this.state;

    const { totalCount, data: movies } = this.getPagedData();

    return (
      <section
        className="resume-section-thisApp p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">edX, Inc. (MOOC plaMorm)</h2>

          <p>Showing {totalCount} item in the database </p>

          <CollapseTable
            movies={movies}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            titles={["Name", "Technology", "Level"]}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </section>
    );
  }
}

export default Moocs;
