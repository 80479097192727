import React, { Component } from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Movies from "./components/movies";
import Skills from "./components/skills";
import NavBar from "./components/navbar";
import Customers from "./components/customers";
import Rentals from "./components/rentals";
import MovieForm from "./components/forms/movieForm";
import LoginForm from "./components/forms/loginForm";
import Home from "./components/home";
import NotFoundComponent from "./components/notFoundComponent";
import RegisterForm from "./components/forms/registerForm";
import ProtectedRoute from "./components/common/protectedRoute";
import Logout from "./components/logout";
import { myNotesURL } from "./config.json";
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <main className="container">
          <NavBar user={user} />
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            <Route path="/home" component={Home} />
            <ProtectedRoute path="/movies/:id" component={MovieForm} />
            {/* <Route
              path="/movies/:id"
              render={props => {
                if (!user) return <Redirect to="/login" />;
                return <MovieForm {...props} />;
              }}
            /> */}
            <Route
              path="/skills"
              render={props => <Skills {...props} user={user} />}
            />
            <Route
              path="/skillsKitchen"
              render={props => <Movies {...props} user={user} />}
            />
            <ProtectedRoute path="/customers" component={Customers} />
            <Route
              path="/customer/:id"
              render={props => <Skills {...props} />}
            />
            <Route path="/rentals" component={Rentals} />
            < Route path = '/my-notes'
            component = {
              () => {
                window.location.href = myNotesURL;
                return null;
              }
            }
            />
            <Route path="/register" component={RegisterForm} />
            <Route path="/not-found" component={NotFoundComponent} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="not-found" />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
