const genres = [{
  "_id": "5c73f6bcdcd6594be38ef02a",
  "name": "Back-End"
}, {
  "_id": "5c73f6bcdcd6594be38ef031",
  "name": "Front-End"
}, {
  "_id": "5c73f6bcdcd6594be38ef03b",
  "name": "Teachnologies"
}, {
  "_id": "5c73f6bcdcd6594be38ef038",
  "name": "UI & UX DESIGN"
}]


export function getGenres() {
  return genres;
}
