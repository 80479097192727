import React from "react";

const ThisApp = () => {
  return (
    <section
      className="resume-section-thisApp p-3 p-lg-5 d-flex align-items-center"
      id="skills"
    >
      <div className="w-100">
        <h2 className="mb-5">About this app</h2>

        <div className="subheading mb-3">Programming Languages &amp; Tools</div>
        <ul className="list-inline dev-icons">
          <li className="list-inline-item">
            <i className="fab fa-node" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-react" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-js" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-html5" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-npm" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-github" />
          </li>
          <li className="list-inline-item">
            <i className="fab fa-aws" />
          </li>
          <li className="list-inline-item">
            <i className="fas fa-database" />
          </li>
        </ul>

        <ul>
          <li>back-end: NodeSJ, RESTful APIs</li>
          <li>front-end: JavaScript, ReactJS</li>
          <li>Tetsing: Jest</li>
          <li>DB: mongoDB</li>
        </ul>
        <div className="subheading mb-3">Workflow</div>
        <ul className="fa-ul mb-0">
          <li>
            <i className="fa-li fa fa-check" />
            Responsive Design
          </li>
          <li>
            <i className="fa-li fa fa-check" />
            Cross Browser Testing &amp; Debugging
          </li>
          <li>
            <i className="fa-li fa fa-check" />
            Query-First &amp; Hybrid Documents
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ThisApp;
